































import Vue from 'vue';

import BackButtonMobile from '@/components/buttons/BackButtonMobile.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import OutlineRoundedButton from '@/components/buttons/OutlineRoundedButton.vue';
import RoomScenes from '@/components/rooms/RoomScenes.vue';

import { BuildingTopologyWithRoomScenes } from '@/graphqlRequests/queries/BuildingTopologyWithRoomScenes';
import { generateKey } from '@/helpers/StringGenerator';
import { RoomScene, RoomWithScenes } from '@/interfaces/rooms/RoomWithScenes';

export default Vue.extend({
  name: 'ScenesSelector',
  components: {
    BackButtonMobile,
    BackButton,
    OutlineRoundedButton,
    RoomScenes,
  },
  data(): {
    buildingTopology: BuildingTopologyWithRoomScenes.ContentDefinition | undefined;
    selectedSceneIds: string[];
  } {
    return {
      buildingTopology: undefined,
      selectedSceneIds: [],
    };
  },
  apollo: {
    buildingTopology: {
      skip(): boolean {
        return (
          this.$store.state.selectedBuildingId == undefined
          || this.$store.state.selectedAreaId == undefined
        );
      },
      query: BuildingTopologyWithRoomScenes.query,
      variables(): BuildingTopologyWithRoomScenes.VariablesDefinition {
        return {
          buildingId: this.$store.state.selectedBuildingId,
          areaIds: [this.$store.state.selectedAreaId],
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$store.commit('addLoadingProcess', this.buildingTopologyLoadingKey);
        } else {
          this.$store.commit('stopLoadingProcess', this.buildingTopologyLoadingKey);
        }
      },
    },
  },
  computed: {
    buildingTopologyLoadingKey(): string {
      return generateKey();
    },
    rooms(): RoomWithScenes[] {
      const area = this.buildingTopology?.areas.find((buildingArea) => buildingArea.id === this.$store.state.selectedAreaId);
      return area?.rooms || [];
    },
  },
  methods: {
    back() {
      if (this.$route.params.back != undefined) {
        this.$router.push({ name: 'AreaControl', });
      } else {
        this.$store.commit('resetControlMode');
        this.$store.commit('resetSelectedArea');
        this.$router.push({ name: 'AreaSelector', });
      }
    },
    sceneClicked(scene: RoomScene, room: RoomWithScenes) {
      if (this.selectedSceneIds.includes(scene.id)) {
        this.selectedSceneIds = this.selectedSceneIds.filter((sceneId) => sceneId !== scene.id);
      } else {
        const roomScenes = room.roomScenes.map((roomScene) => roomScene.id);
        this.selectedSceneIds = this.selectedSceneIds
          .filter((sceneId) => !roomScenes.includes(sceneId))
          .concat(scene.id);
      }
    },
    saveSceneShortcut() {
      this.$store.commit('setSceneShortcutDraft', this.selectedSceneIds);
      this.$router.push({
        name: 'SceneShortcutName',
        params: this.$route.params,
      });
    },
  },
});
