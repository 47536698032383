

























import Vue from 'vue';

import RoomScene from '@/components/scenes/RoomScene.vue';

import { RoomScene as RoomSceneDefinition, RoomWithScenes } from '@/interfaces/rooms/RoomWithScenes';

export default Vue.extend({
  name: 'RoomScenes',
  components: {
    RoomScene,
  },
  props: {
    room: {
      type: Object as () => RoomWithScenes,
      required: true,
    },
    selectedSceneIds: {
      type: Array as () => string[],
      required: true,
    },
  },
  methods: {
    isSceneSelected(scene: RoomSceneDefinition) {
      return this.selectedSceneIds.includes(scene.id);
    },
  }
});
