































import Vue from 'vue';

import { RoomScene } from '@/interfaces/rooms/RoomWithScenes';

export default Vue.extend({
  name: 'RoomScene',
  props: {
    scene: {
      type: Object as () => RoomScene,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
});
