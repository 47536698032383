import gql from "graphql-tag";

export namespace BuildingTopologyWithRoomScenes {

  export const query = gql`
    query buildingTopology($buildingId: String!, $areaIds: [String!]) {
      buildingTopology(buildingId: $buildingId) {
        id
        name
        areas: floors(ids: $areaIds) {
          id
          name
          rooms {
            id
            name
            roomScenes {
              id
              name
            }
          }
        }
      }
    }
  `;

  export interface VariablesDefinition {
    buildingId: string;
    areaIds: string[];
  }

  export interface ContentDefinition {
    id: string;
    name: string;
    areas: {
      id: string;
      name: string;
      rooms: {
        id: string;
        name: string;
        roomScenes: {
          id: string;
          name: string;
        }[];
      }[];
    }[];
  }

  export interface ResultDefinition {
    buildingTopology: ContentDefinition;
  }

}
